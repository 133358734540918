import React, { useState } from "react";
import "./styles.css";

const SetupForm = ({
  userId,
  date,
  segment,
  modality,
  setUserId,
  setDate,
  setSegment,
  setParams,
}) => {
  const [user, setUser] = useState(userId);
  const [video, setVideo] = useState(date);
  const [seg, setSeg] = useState(segment);
  const [isDisabled, setIsDisabled] = useState(true);

  const saveChanges = () => {
    setUserId(user);
    setDate(video);
    setSegment(seg);
    setIsDisabled(true);
    setParams({ userId: user, date: video, segment: seg, modality: modality });
  };

  return (
    <div className="setupform">
      <input
        type="text"
        name="userId"
        className="setupform_userid"
        placeholder="user ID"
        defaultValue={userId}
        onChange={(e) => {
          setUser(e.target.value);
          setIsDisabled(false);
        }}
      />
      <select
        name="videoId"
        className="setupform_videoid"
        defaultValue={date}
        onChange={(e) => {
          setVideo(e.target.value);
          setIsDisabled(false);
        }}
      >
        <option value="">select video</option>
        <option value="06092023">Jun 9, 2023</option>
        <option value="12032022">Dec 3, 2022</option>
        <option value="11152022">Nov 15, 2022</option>
        <option value="03232022">Mar 23, 2022</option>
        <option value="03152022">Mar 15, 2022</option>
        <option value="12022021">Dec 2, 2021</option>
        <option value="09122021">Sep 12, 2021</option>
        <option value="06162021">Jun 16, 2021</option>
        <option value="02282021">Feb 28, 2021</option>
        <option value="02012021">Feb 1, 2021</option>
        <option value="01272021">Jan 27, 2021</option>
        <option value="06262020">Jun 26, 2020</option>
        <option value="01252020">Jan 25, 2020</option>
        <option value="01152020">Jan 15, 2020</option>
        <option value="12022019">Dec 2, 2019</option>
        <option value="11222019">Nov 22, 2019</option>
        <option value="11152019">Nov 15, 2019</option>
        <option value="10062019">Oct 6, 2019</option>
      </select>
      <select
        name="segmentId"
        className="setupform_videoid"
        defaultValue={segment}
        onChange={(e) => {
          setSeg(e.target.value);
          setIsDisabled(false);
        }}
      >
        <option value={0}>select segment</option>
        <option value={1}>segment 1</option>
        <option value={2}>segment 2</option>
        <option value={3}>segment 3</option>
        <option value={4}>segment 4</option>
        <option value={5}>segment 5</option>
        <option value={6}>segment 6</option>
        <option value={7}>segment 7</option>
        <option value={8}>segment 8</option>
        <option value={9}>segment 9</option>
        <option value={10}>segment 10</option>
      </select>
      <button
        className="setupform_enter"
        onClick={saveChanges}
        disabled={isDisabled}
      >
        Enter
      </button>
    </div>
  );
};

export default SetupForm;
