import React, { useState, useMemo, useEffect } from "react";
import AnimationCard from "../AnimationCard";
import AnnotationCard from "../AnnotationCard";
import axios from "axios";
import "../styles.css";
import _ from "lodash";
// import { useNavigate, useLocation } from "react-router-dom";

let apiURL = "https://spacewalk-data.org/api";
// let apiURL = "http://localhost:8080/api";
let apiKey = "1234";

const indices = [374, 397, 434, 439, 440, 444, 448, 462, 469, 516];
const validation = {
  374: "EV1 gain control of new antenna and use robotic arm to take it to worksite",
  397: "EV1 gain control of new antenna and use robotic arm to take it to worksite",
  434: "NA",
  439: "NA",
  440: "EV2 drives stanchion bolt to hold new antenna in place",
  444: "EV2 reconnect 3 connectors (heat, functional, data) to new antenna",
  448: "EV2 reconnect 3 connectors (heat, functional, data) to new antenna",
  462: "EV1 release gimbal locks on new antenna",
  469: "EV1 release gimbal locks on new antenna",
  516: "Perform checkout on new antenna",
};
const threshold = 0.8;
// const failUrl = {
//   0: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
//   1: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
//   2: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
//   3: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
//   4: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
//   5: "https://app.prolific.co/submissions/complete?cc=CNTCN3PK",
// };

export default function App() {
  // const location = useLocation();
  //   const searchParams = new URLSearchParams(location.search);

  const date = "12022021";
  const segment = 4;
  const userId = "TRAINING";
  const [animations, setAnimations] = useState([]);
  const [videodata, setVideoData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [colorDict, setColorDict] = useState({});
  const [animationIdx, setAnimationIdx] = useState(0);
  const [results, setResults] = useState({});

  const getAnimations = () => {
    axios
      .get(`${apiURL}/animations?apiKey=${apiKey}&date=${date}`)
      .then((response) => {
        console.log("Animations fetched successfully", response);
        const filteredAnimations = response.data.animations.filter(
          (animation) => animation.segment === Number(segment)
        );
        const sortedAnimations = filteredAnimations.sort(
          (a, b) => a.index - b.index
        );
        setAnimations(sortedAnimations);
        setAnimationIdx(sortedAnimations[0].index);
      })
      .catch((error) => {
        console.log("Error fetching animations", error);
        setAnimations([]);
      });
  };

  const getVideoData = () => {
    axios
      .get(`${apiURL}/videodata?apiKey=${apiKey}&date=${date}`)
      .then((response) => {
        console.log("Video Data fetched successfully", response);
        const filteredVideoData = response.data.videodata.filter((videodata) =>
          indices.includes(videodata.index)
        );
        const sortedVideoData = filteredVideoData.sort(
          (a, b) => a.index - b.index
        );
        setVideoData(sortedVideoData);
      })
      .catch((error) => {
        console.log("Error fetching videodata", error);
        setVideoData([]);
      });
  };

  const getColors = () => {
    const updatedColors = animations.reduce(
      (acc, animation) => {
        acc[animation.label] = `#${Math.floor(
          Math.random() * 16777215
        ).toString(16)}`;
        return acc;
      },
      { "": "white", NA: "gray" }
    );
    setColorDict(updatedColors);
  };

  const getLabels = () => {
    const updatedLabels = animations.map((animation) => animation.label);
    setLabels(updatedLabels);
  };

  useMemo(() => {
    if (date && segment) {
      getVideoData();
      getAnimations();
    }
  }, [userId, date, segment]);

  useMemo(() => {
    getColors();
    getLabels();
  }, [animations, videodata, segment]);

  const buildAnnotations = async () => {
    let updatedAnnotations = [];

    try {
      await Promise.all(
        videodata.map(async (videodata) => {
          try {
            const response = await axios.get(
              `${apiURL}/annotations?apiKey=${apiKey}&date=${date}&userId=${userId}&index=${videodata.index}`
            );
            updatedAnnotations.push(response.data.annotation);
          } catch (error) {
            const annotation = {
              date: videodata.videoDate,
              label: "",
              start: videodata.start,
              end: videodata.end,
              index: Number(videodata.index),
              segment: Number(videodata.segment),
              userId: userId,
            };
            try {
              console.log("Creating annotation", annotation);
              const response = await axios.post(
                `${apiURL}/annotations?apiKey=${apiKey}`,
                annotation
              );
              console.log("Annotation created successfully", response);
              updatedAnnotations.push(response.data.post._doc);
            } catch (error) {
              console.log("Error creating annotation", error);
            }
          }
        })
      );

      setAnnotations(updatedAnnotations.sort((a, b) => a.index - b.index));
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  const [annotations, setAnnotations] = useState([]);
  const debouncedBuildAnnotations = _.debounce(buildAnnotations, 500);
  useEffect(() => {
    debouncedBuildAnnotations();
    return () => {
      debouncedBuildAnnotations.cancel();
    };
  }, [videodata]);

  // const navigate = useNavigate();
  const evaluateResults = () => {
    let correct = 0;
    for (let i = 0; i < indices.length; i++) {
      try {
        if (results[indices[i]] === validation[indices[i]]) {
          correct++;
        }
      } catch (error) {
        console.log();
      }
    }
    if (correct / indices.length >= threshold) {
      // navigate("/annotating" + location.search);
      window.location.replace(
        "https://app.prolific.co/submissions/complete?cc=C1M30F7J" // qualified for follow
      );
    } else {
      //   window.location.replace(failUrl[searchParams.get("segment") || 0]);
      window.location.replace(
        "https://app.prolific.co/submissions/complete?cc=CUGT59P1" // failed screener
      );
    }
  };

  return (
    <div className="App">
      <div className="titlecard">
        <img src="spaceman2.png" alt="Spacewalk Logo" width="100"></img>
        <h1>Spacewalk Video Annotation Tool Screening</h1>
      </div>
      <div className="textcard">
        <div className="title">Welcome!</div>
        <div className="content">
          The International Space Station (ISS) is a habitable space station in
          low Earth orbit. Often, astronauts living on the space station will
          conduct “spacewalks” where they go outside the space station.
          Astronauts go on spacewalks for a variety of reasons, including to
          perform science experiments, test equipment, or carry out
          maintenance/repairs. Each spacewalk lasts about 5 hours from when the
          astronauts exit the space station to when they re-enter. For more
          background information on spacewalks and a dictionary of space
          lingo/abbreviations, click{" "}
          <a
            href="https://docs.google.com/document/d/1hzIC58kxhwZQSi_T75ndi34rEfRSYQpAwIHXY5hTPaU/edit?usp=sharing"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </div>
      <div className="textcard">
        <div className="title">Screening</div>
        <div className="content">
          Your task is to label clips from an International Space Station
          spacewalk livestream. In this stage, you will label a few clips from a
          spacewalk livestream. If more than 80% of your labels are correct, you
          will be able to coninue to the next stage.
        </div>
      </div>
      <div className="textcard">
        <div className="title">Animations</div>
        <div className="content">
          Below, you will find a series of animated clips. Each of these clips
          is labeled with a short description. Watch all of these videos to get
          an idea of the tasks the astronauts will be performing during this
          spacewalk.
        </div>
      </div>
      <div className="content-container">
        <div className="card-container">
          {animations.map((animation) => (
            <AnimationCard
              key={animation._id}
              animation={animation}
              colorDict={colorDict}
            />
          ))}
        </div>
        <div className="textcard">
          <div className="title">Annotations</div>
          <div className="content">
            Under the "Annotations" tab, you will see a series of short video
            clips. These are clips from the livestream that you are tasked with
            labeling. Below each clip, you will find a selector containing a few
            labels for you to choose from. Your task is to watch each clip and
            select the label that best describes the task being performed. If
            the clip does not contain a task, select "Irrelevant". Remember that
            there will be multiple clips with the same label and the order of
            the clips will roughly correspond to the order of the animations.
            For some tips and FAQs, click{" "}
            <a
              href="https://docs.google.com/document/d/19cKX2u9wLlh2QHbi-fXBVK8Kps6RUtjxcMKvJnugAZs/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            .
          </div>
        </div>
        <div className="card-container">
          {annotations.map((annotation) => (
            <AnnotationCard
              key={annotation._id}
              userId={userId}
              apiURL={apiURL}
              labels={labels}
              colorDict={colorDict}
              annotation={annotation}
              animationIdx={animationIdx}
              setResults={setResults}
            />
          ))}
        </div>
      </div>

      {videodata.length === 0 ? null : (
        <div className="container">
          <div className="textcard">
            <div className="title">Evaluate</div>
            <div className="content">
              When you are done labeling, press the "submit" button to have your
              labels evaluated. If the accuracy of your labels is above 80%, you
              will be redirected to continue with the task.
            </div>
          </div>
          <div className="pagination">
            <button className="submit_annotations" onClick={evaluateResults}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
