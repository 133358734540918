import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Training from "./pages/Training";
import Screening from "./pages/Screening";
import Annotating from "./pages/Annotating";
import Certificate from "./pages/Certificate";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Training />} />
        <Route exact path="/training" element={<Training />} />
        <Route exact path="/screening" element={<Screening />} />
        <Route exact path="/annotating" element={<Annotating />} />
        <Route exact path="/certificate" element={<Certificate />} />
      </Routes>
    </Router>
  );
}

export default App;
