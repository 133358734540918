import React from "react";

const AudioOnly = ({ videoUrl }) => {
  return (
    <div>
      <audio controls width="100%">
        <source src={videoUrl} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};

export default AudioOnly;
