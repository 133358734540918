import React from "react";
import "./styles.css";
import VideoPlayer from "./VideoPlayer";

const AnimationCard = ({ animation, colorDict }) => {
  const color = colorDict[animation["label"]];
  return (
    <div className="annotationcard" style={{ backgroundColor: color }}>
      <div className="video-container">
        <VideoPlayer
          videoUrl={`https://spacewalkvideos.s3.us-west-2.amazonaws.com/${animation.videoDate}/animations/${animation.start}.mp4`}
        />
      </div>
      <div className="animation_label">
        {animation.index}. {animation.label}
      </div>
    </div>
  );
};

export default AnimationCard;
