import React, { useRef, useEffect } from "react";

const VideoOnly = ({ videoUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.muted = true; // Disable audio
      videoElement.controls = true; // Show video controls
      videoElement.addEventListener("volumechange", () => {
        videoElement.muted = true; // Ensure audio remains muted
      });
    }
  }, []);

  return (
    <div>
      <video ref={videoRef} width="100%">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoOnly;
