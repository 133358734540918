import React, { useState, useEffect, useRef } from "react";
import AnimationCard from "../AnimationCard";
import CertificateCard from "../CertificateCard";
import axios from "axios";
import "../styles.css";
import { useSearchParams } from "react-router-dom";

let apiURL = "https://spacewalk-data.org/api";
// const apiURL = "http://localhost:8080/api";
const dates = [
  "06092023",
  "06092023",
  "12022019",
  "06092023",
  "09122021",
  "09122021",
  "09122021",
  "02282021",
  "06262020",
  "06262020",
  "12022019",
  "02282021",
  "06262020",
  "12022019",
  "06092023",
  "06262020",
  "12022019",
  "02282021",
  "09122021",
  "11152019",
];
const segments = [2, 4, 2, 1, 5, 1, 4, 7, 3, 1, 1, 4, 4, 4, 3, 2, 3, 2, 3, 3];
const numClips = [
  331, 18, 87, 66, 11, 150, 132, 21, 24, 50, 126, 4, 118, 4, 69, 78, 326, 7, 25,
  10,
];
const trueLabels = [
  "EV1 & EV2 electronically connect iROSA to ISS power system",
  "EV1 & EV2 head back to airlock",
  "Luca connect six fluid connections",
  "EV2 release bolts on iROSA",
  "EV1 stow spare FPMU",
  "EV1 & EV2 exit airlock",
  "EV1 exit foot restraint and begin cleanup",
  "EV2 put bag in airlock and enter",
  "Bob installs adapter plate in open slot 2",
  "Bob grabs foot restraint and follows outward",
  "Luca & Drew connect power and data cables",
  "EV1 attach mid strut to body tether and enter foot restraint",
  "Chris & Bob take the battery to the truss",
  "Drew bring bags back to airlock",
  "EV1 moves back to iROSA carrier to configure carrier beams",
  "Chris & Bob install battery in empty slot X",
  "Luca & Drew complete final 2 suages",
  "EV2 hand upper triangle to EV1",
  "EV1 enter foot restraint and receive strut",
  "Drew jettisons the debris shield",
];
function defaultdict(defaultValue, label) {
  return new Proxy(
    {},
    {
      get: (target, prop) => {
        if (prop === label) {
          return "#cc9966b3"; // Custom value for the specified label
        } else {
          return defaultValue; // Default value for other properties
        }
      },
    }
  );
}

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId") || "";
  const [animations, setAnimations] = useState([]);
  const [videodata, setVideoData] = useState([]);
  const idx = Number(searchParams.get("idx")) || 0;
  const [date, setDate] = useState(dates[idx]);
  const [segment, setSegment] = useState(segments[idx]);
  const [trueLabel, setTrueLabel] = useState(trueLabels[idx]);

  const [certificate, setCertificate] = useState({});

  const colorDict = defaultdict("grey", trueLabel);

  const getAnimations = () => {
    axios
      .get(`${apiURL}/animations?date=${date}`)
      .then((response) => {
        console.log("Animations fetched successfully", response);
        const filteredAnimations = response.data.animations.filter(
          // (animation) => animation.segment === Number(segment)
          (animation) => animation.label === trueLabel
        );
        const sortedAnimations = filteredAnimations.sort(
          (a, b) => a.index - b.index
        );
        setAnimations(sortedAnimations);
      })
      .catch((error) => {
        console.log("Error fetching animations", error);
        setAnimations([]);
      });
  };

  const getVideoData = () => {
    let updatedVD = [];

    for (let i = 0; i < numClips[idx]; i++) {
      updatedVD.push({
        videoUrl: `https://spacewalkvideos.s3.us-west-2.amazonaws.com/certificates/${date}/${segment}/${i}.mp4`,
        index: i,
      });
    }
    setVideoData(updatedVD);
  };

  const buildCertificate = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/certificates?date=${dates[idx]}&userId=${userId}&segment=${segments[idx]}`
      );
      console.log("Certificate fetched successfully", response);
      setCertificate(response.data.certificate);
    } catch (error) {
      try {
        console.log("Creating certificate", {
          date: dates[idx],
          segment: segments[idx],
          userId: userId,
          label: trueLabels[idx],
        });
        const response = await axios.post(`${apiURL}/certificates`, {
          date: dates[idx],
          segment: segments[idx],
          userId: userId,
          label: trueLabels[idx],
        });
        console.log("Certificate created successfully", response);
        setCertificate(response.data.post._doc);
      } catch (error) {
        console.log("Error creating certificate", error);
      }
    }
  };

  const containerRef = useRef();

  useEffect(() => {
    setDate(dates[idx]);
    setSegment(segments[idx]);
    setTrueLabel(trueLabels[idx]);
    buildCertificate();
  }, [idx]);

  useEffect(() => {
    if (date && segment) {
      getVideoData();
      getAnimations();
    }
  }, [certificate]);

  return (
    <div className="App">
      <div className="titlecard">
        <img src="spaceman2.png" alt="Spacewalk Logo" width="100"></img>
        <h1>Spacewalk Video Annotation Tool</h1>
      </div>
      <div className="textcard">
        <div className="title">Welcome!</div>
        <div className="content">
          The International Space Station (ISS) is a habitable space station in
          low Earth orbit. Often, astronauts living on the space station will
          conduct “spacewalks” where they go outside the space station.
          Astronauts go on spacewalks for a variety of reasons, including to
          perform science experiments, test equipment, or carry out
          maintenance/repairs. For more background information on spacewalks and
          a dictionary of space lingo/abbreviations, click{" "}
          <a
            href="https://docs.google.com/document/d/1hzIC58kxhwZQSi_T75ndi34rEfRSYQpAwIHXY5hTPaU/edit?usp=sharing"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </div>
      <div className="textcard">
        <div className="title">Task</div>
        <div className="content">
          You are tasked with selecting the minimum set of clips that are both
          necessary and sufficient to convince you that the marked annotation
          for the data is indeed correct.
        </div>
      </div>
      <div className="content-container">
        <div className="textcard">
          <div className="title">Animations</div>
          <div className="content">
            Below, you will find a single animated clip. This clip represents a
            task and is labeled with a short description.
          </div>
        </div>
        <div className="card-container">
          {animations.map((animation) => (
            <AnimationCard
              key={animation._id}
              animation={animation}
              colorDict={colorDict}
            />
          ))}
        </div>
        <div className="textcard">
          <div className="title">Annotations</div>
          <div className="content">
            Here, you will see a series of 5 second video clips. These are
            subclips from a single clip from the livestream. Each clip has a
            checkbox underneath it. They all belong to the task shown in the
            animation clip above. Your task is to select the minimum set of
            clips that you need in order to be confident that these clips do
            indeed belong to the task from the animation clip above.
          </div>
        </div>
        <div className="card-container" ref={containerRef}>
          {videodata.map((data) => (
            <CertificateCard
              apiURL={apiURL}
              certificate={certificate}
              data={data}
            />
          ))}
        </div>
        <div className="container">
          {idx === dates.length - 1 ? (
            <div
              className="textcard"
              style={{ backgroundColor: "rgba(76, 154, 42, 0.5)" }}
            >
              <div className="title">
                Click the Completion URL When You Are Done!
              </div>
              <div className="content">
                <a
                  href="https://app.prolific.co/submissions/complete?cc=C18XJSLY"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Click Here to Finish Task
                </a>
              </div>
            </div>
          ) : (
            <div
              className="textcard"
              style={{ backgroundColor: "rgba(220, 20, 60, 0.5)" }}
            >
              <div className="title">Task Incomplete!</div>
              <div className="content">{dates.length - idx} remaining</div>
            </div>
          )}
          <div className="pagination">
            <select
              className="setupform_videoid"
              value={idx + 1}
              onChange={(e) => {
                setSearchParams({ userId: userId, idx: e.target.value - 1 });
              }}
            >
              {dates.map((date, i) => (
                <option key={Number(i)} value={Number(i) + 1}>
                  {Number(i) + 1}
                </option>
              ))}
            </select>
            <button
              className="submit_annotations"
              onClick={() => {
                setSearchParams({
                  userId: userId,
                  idx: Math.min(idx + 1, dates.length - 1),
                });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
