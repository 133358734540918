import React, { useMemo, useEffect, useState } from "react";
import "./styles.css";
import axios from "axios";
import VideoPlayer from "./VideoPlayer";
import VideoOnly from "./VideoOnly";
import AudioOnly from "./AudioOnly";

const AnnotationCard = ({
  userId,
  apiKey,
  apiURL,
  labels,
  colorDict,
  annotation,
  animationIdx,
  validation,
  setResults,
  modality,
}) => {
  let trueLabel = "";
  if (validation) {
    trueLabel = validation[0];
  }

  const [color, setColor] = useState(colorDict[annotation.label]);

  useMemo(() => {
    setColor(colorDict[annotation.label]);
  }, [colorDict]);

  const [player, setPlayer] = useState(null);
  useEffect(() => {
    if (modality === "video") {
      setPlayer(
        <VideoOnly
          videoUrl={`https://spacewalkvideos.s3.us-west-2.amazonaws.com/${annotation.videoDate}/annotations/${annotation.start}.mp4`}
        ></VideoOnly>
      );
    } else if (modality === "audio") {
      setPlayer(
        <AudioOnly
          videoUrl={`https://spacewalkvideos.s3.us-west-2.amazonaws.com/${annotation.videoDate}/annotations/${annotation.start}.mp4`}
        ></AudioOnly>
      );
    } else {
      setPlayer(
        <VideoPlayer
          videoUrl={`https://spacewalkvideos.s3.us-west-2.amazonaws.com/${annotation.videoDate}/annotations/${annotation.start}.mp4`}
        ></VideoPlayer>
      );
    }
  }, [modality]);

  const handleEdit = (e) => {
    const { name, value } = e.target;
    annotation[name] = value;
    annotation["userId"] = userId;
    saveChanges();
  };
  const saveChanges = () => {
    if (userId === "TRAINING") {
      setColor(colorDict[annotation["label"]]);
      if (setResults) {
        setResults((prev) => {
          return {
            ...prev,
            [annotation.index]: annotation.label,
          };
        });
      }
      return;
    }
    const url =
      modality === "video" || modality === "audio"
        ? `${apiURL}/modalities/${annotation._id}`
        : `${apiURL}/annotations/${annotation._id}`;
    axios
      .put(url, {
        id: annotation._id,
        ...annotation,
      })
      .then((response) => {
        console.log("Annotation updated successfully", response);
        setColor(colorDict[annotation["label"]]);
      })
      .catch((error) => {
        console.log("Error updating annotation", error);
      });
  };

  return (
    <div className="annotationcard" style={{ backgroundColor: color }}>
      <div className="video-container">{player}</div>
      <div className="label_selector">
        <select
          name="label"
          className="card_label"
          id={annotation._id}
          value={annotation.label}
          onChange={handleEdit}
        >
          <option value="">select label</option>
          <option value="NA">Irrelevant</option>
          {modality !== "video" && modality !== "audio" ? (
            <option value="Unsure">Unsure</option>
          ) : null}
          {labels.map((label, index) => (
            <option key={label} value={label}>
              {`${index + animationIdx}. ${label}`}
            </option>
          ))}
        </select>
      </div>
      {/* {annotation.index} */}
      {trueLabel === "" ? null : (
        <div className="true_label">
          {annotation.label === trueLabel ? (
            <p>Correct! {validation[1]}</p>
          ) : (
            <p>Incorrect, try again!</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AnnotationCard;
