import React, { useState, useMemo, useEffect } from "react";
import AnimationCard from "../AnimationCard";
import AnnotationCard from "../AnnotationCard";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import "../styles.css";
import _ from "lodash";

let apiURL = "https://spacewalk-data.org/api";
// let apiURL = "http://localhost:8080/api";
let apiKey = "1234";

const indices = [525, 527, 618, 647, 905];
const validation = {
  525: [
    "EV1 & EV2 move to logistics carrier",
    "We hear the astronaut stating that he is ready for 'GCA' (Ground Controlled Approach). The video shows the helmet camera of the astronaut where his hands are free as he is being held up by the robotic arm.",
  ],
  527: [
    "EV1 & EV2 move to logistics carrier",
    "The astronaut can be seen on the robotic arm moving towards the logistics carrier.",
  ],
  595: [
    "EV2 drive two bolts to secure old antenna to logistics carrier",
    "Although the clip is short, we are still able to see the astronaut using the drill to drive the bolts.",
  ],
  618: [
    "EV2 drive two bolts to secure old antenna to logistics carrier",
    "The video shows the astronauts handling the drill they used to drive the bolts.",
  ],
  647: [
    "NA",
    "This clip is of the ground control room on Earth. Since it does not show the astronauts or what they are doing, we can label this clip as 'Noise'.",
  ],
  905: [
    "Install thermal blanket on degraded antenna",
    "While the narration isn't very useful here and the video is a bit blurry, we can make out what looks like a large white sheet. Since we know the astronauts will be installing a thermal blanket, we can assume that this is the thermal blanket.",
  ],
};

export default function App() {
  const location = useLocation();

  const date = "12022021";
  const segment = 5;
  const userId = "TRAINING";
  const [animations, setAnimations] = useState([]);
  const [videodata, setVideoData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [colorDict, setColorDict] = useState({});
  const [animationIdx, setAnimationIdx] = useState(0);

  const getAnimations = () => {
    axios
      .get(`${apiURL}/animations?date=${date}`)
      .then((response) => {
        console.log("Animations fetched successfully", response);
        const filteredAnimations = response.data.animations.filter(
          (animation) => animation.segment === Number(segment)
        );
        const sortedAnimations = filteredAnimations.sort(
          (a, b) => a.index - b.index
        );
        setAnimations(sortedAnimations);
        setAnimationIdx(sortedAnimations[0].index);
      })
      .catch((error) => {
        console.log("Error fetching animations", error);
        setAnimations([]);
      });
  };

  const getVideoData = () => {
    axios
      .get(`${apiURL}/videodata?date=${date}`)
      .then((response) => {
        console.log("Video Data fetched successfully", response);
        const filteredVideoData = response.data.videodata.filter((videodata) =>
          indices.includes(videodata.index)
        );
        const sortedVideoData = filteredVideoData.sort(
          (a, b) => a.index - b.index
        );
        setVideoData(sortedVideoData);
      })
      .catch((error) => {
        console.log("Error fetching videodata", error);
        setVideoData([]);
      });
  };

  const getColors = () => {
    const updatedColors = animations.reduce(
      (acc, animation) => {
        acc[animation.label] = `#${Math.floor(
          Math.random() * 16777215
        ).toString(16)}`;
        return acc;
      },
      { "": "white", NA: "gray" }
    );
    setColorDict(updatedColors);
  };

  const getLabels = () => {
    const updatedLabels = animations.map((animation) => animation.label);
    setLabels(updatedLabels);
  };

  useMemo(() => {
    if (date && segment) {
      getVideoData();
      getAnimations();
    }
  }, [userId, date, segment]);

  useMemo(() => {
    getColors();
    getLabels();
  }, [animations, videodata, segment]);

  const buildAnnotations = async () => {
    let updatedAnnotations = [];

    try {
      await Promise.all(
        videodata.map(async (videodata) => {
          try {
            const response = await axios.get(
              `${apiURL}/annotations?apiKey=${apiKey}&date=${date}&userId=${userId}&index=${videodata.index}`
            );
            updatedAnnotations.push(response.data.annotation);
          } catch (error) {
            const annotation = {
              date: videodata.videoDate,
              label: "",
              start: videodata.start,
              end: videodata.end,
              index: Number(videodata.index),
              segment: Number(videodata.segment),
              userId: userId,
            };
            try {
              console.log("Creating annotation", annotation);
              const response = await axios.post(
                `${apiURL}/annotations?apiKey=${apiKey}`,
                annotation
              );
              console.log("Annotation created successfully", response);
              updatedAnnotations.push(response.data.post._doc);
            } catch (error) {
              console.log("Error creating annotation", error);
            }
          }
        })
      );

      setAnnotations(updatedAnnotations.sort((a, b) => a.index - b.index));
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  const [annotations, setAnnotations] = useState([]);
  const debouncedBuildAnnotations = _.debounce(buildAnnotations, 500);
  useEffect(() => {
    debouncedBuildAnnotations();
    return () => {
      debouncedBuildAnnotations.cancel();
    };
  }, [videodata]);

  return (
    <div className="App">
      <div className="titlecard">
        <img src="spaceman2.png" alt="Spacewalk Logo" width="100"></img>
        <h1>Spacewalk Video Annotation Tool Training</h1>
      </div>
      <div className="textcard">
        <div className="title">Welcome!</div>
        <div className="content">
          The International Space Station (ISS) is a habitable space station in
          low Earth orbit. Often, astronauts living on the space station will
          conduct “spacewalks” where they go outside the space station.
          Astronauts go on spacewalks for a variety of reasons, including to
          perform science experiments, test equipment, or carry out
          maintenance/repairs. Each spacewalk lasts about 5 hours from when the
          astronauts exit the space station to when they re-enter. For more
          background information on spacewalks and a dictionary of space
          lingo/abbreviations, click{" "}
          <a
            href="https://docs.google.com/document/d/1hzIC58kxhwZQSi_T75ndi34rEfRSYQpAwIHXY5hTPaU/edit?usp=sharing"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </div>
      <div className="textcard">
        <div className="title">Training</div>
        <div className="content">
          Your task is to label clips from an International Space Station
          spacewalk livestream. This stage is about getting familiar with the
          videos and annotation procedure. Note that there will be a screening
          task after this stage!
        </div>
      </div>
      <div className="textcard">
        <div className="title">Animations</div>
        <div className="content">
          Below, you will find a series of animated clips. Each of these clips
          is labeled with a short description. Watch all of these videos to get
          an idea of the tasks the astronauts will be performing during this
          spacewalk.
        </div>
      </div>
      <div className="content-container">
        <div className="card-container">
          {animations.map((animation) => (
            <AnimationCard
              key={animation._id}
              animation={animation}
              colorDict={colorDict}
            />
          ))}
        </div>
        <div className="textcard">
          <div className="title">Annotations</div>
          <div className="content">
            Under the "Annotations" tab, you will see a series of short video
            clips. These are clips from the livestream that you are tasked with
            labeling. Below each clip, you will find a selector containing a few
            labels for you to choose from. Your task is to watch each clip and
            select the label that best describes the task being performed. If
            the clip does not contain a task, select "Irrelevant". In the
            training stage, there is an indicator letting you know if your
            annotation is correct. When you select a correct annotation, there
            will also be a short explanation giving you an idea of what you
            should be looking for when making future annotations. For some tips
            and FAQs, click{" "}
            <a
              href="https://docs.google.com/document/d/19cKX2u9wLlh2QHbi-fXBVK8Kps6RUtjxcMKvJnugAZs/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            .
          </div>
        </div>
        <div className="card-container">
          {annotations.map((annotation) => (
            <AnnotationCard
              key={annotation._id}
              userId={userId}
              apiURL={apiURL}
              labels={labels}
              colorDict={colorDict}
              annotation={annotation}
              animationIdx={animationIdx}
              validation={validation[annotation.index]}
            />
          ))}
        </div>
      </div>

      {videodata.length === 0 ? null : (
        <div className="container">
          <div className="textcard">
            <div className="title">Next</div>
            <div className="content">
              When you are ready, press the "continue" button to move on to the
              screening task. The screening task is similar to this one but you
              will not be told if your annotations are correct. In the screening
              task, your annotations will be evaluated for accuracy. If your
              accuracy is above a certain threshold, you will be able to
              continue with the task.
            </div>
          </div>
          <div className="pagination">
            <Link
              style={{ width: "100%" }}
              to={{
                pathname: "/screening",
                search: location.search,
              }}
            >
              <button className="submit_annotations">Continue</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
