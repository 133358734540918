import React, { useMemo, useState } from "react";
import "./styles.css";
import axios from "axios";
import VideoPlayer from "./VideoPlayer";

const CertificateCard = ({ apiURL, certificate, data }) => {
  const [color, setColor] = useState("white");
  const [checked, setChecked] = useState(false);

  useMemo(() => {
    if (certificate.clips) {
      if (certificate.clips.includes(data.index)) {
        setChecked(true);
        setColor("#6c99b3");
      }
    }
  }, [certificate]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      axios
        .put(`${apiURL}/certificates/add/${certificate._id}`, {
          id: certificate._id,
          clip: data.index,
        })
        .then((response) => {
          console.log("Certificate updated successfully", response);
          setChecked(true);
          setColor("#6c99b3");
        })
        .catch((error) => {
          console.log("Error updating certificate", error);
        });
    } else {
      axios
        .put(`${apiURL}/certificates/remove/${certificate._id}`, {
          id: certificate._id,
          clip: data.index,
        })
        .then((response) => {
          console.log("Certificate updated successfully", response);
          setChecked(false);
          setColor("white");
        })
        .catch((error) => {
          console.log("Error updating certificate", error);
        });
    }
  };

  return (
    <div
      className="annotationcard"
      style={{ backgroundColor: color }}
      key={data.index}
    >
      <div className="video-container">
        <VideoPlayer videoUrl={data.videoUrl} />
      </div>
      <div className="label_selector">
        <div className="checkbox">
          <input
            type="checkbox"
            onChange={handleCheckboxChange}
            checked={checked}
            style={{ width: "20px", height: "20px" }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default CertificateCard;
