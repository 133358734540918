import React from "react";
import "./styles.css";

const TaskComplete = ({ remaining, segment }) => {
  // const completion_links = {
  //   1: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  //   2: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  //   3: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  //   4: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  //   5: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  //   6: "https://app.prolific.co/submissions/complete?cc=C18XJSLY",
  // };
  return remaining === 0 ? (
    <div
      className="textcard"
      style={{ backgroundColor: "rgba(76, 154, 42, 0.5)" }}
    >
      <div className="title">Task Complete!</div>
      <div className="content">
        <a
          // href={completion_links[segment]}
          href="https://app.prolific.co/submissions/complete?cc=C18XJSLY"
          target="_blank"
          rel="noreferrer noopener"
        >
          Click Here to Finish Task
        </a>
      </div>
    </div>
  ) : (
    <div
      className="textcard"
      style={{ backgroundColor: "rgba(220, 20, 60, 0.5)" }}
    >
      <div className="title">Task Incomplete!</div>
      <div className="content">{remaining} remaining</div>
    </div>
  );
};

export default TaskComplete;
