import React, { useState, useMemo, useEffect } from "react";
import SetupForm from "../SetupForm";
import AnimationCard from "../AnimationCard";
import AnnotationCard from "../AnnotationCard";
import axios from "axios";
import "../styles.css";
import TaskComplete from "../TaskComplete";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

let PageSize = 4;
let apiURL = "https://spacewalk-data.org/api";
// let apiURL = "http://localhost:8080/api";
let apiKey = "1234";

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState(searchParams.get("userId") || "");
  const [date, setDate] = useState(searchParams.get("date") || "");
  const [animations, setAnimations] = useState([]);
  const [videodata, setVideoData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [colorDict, setColorDict] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [segment, setSegment] = useState(
    Number(searchParams.get("segment")) || 0
  );
  const modality = searchParams.get("modality");
  const [animationIdx, setAnimationIdx] = useState(0);
  // const [apiKey, setApiKey] = useState("");
  const [currentVideoData, setCurrentVideoData] = useState([]);

  const getCurrentVideoData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentVideoData(videodata.slice(firstPageIndex, lastPageIndex));
  };

  // const getApiKey = () => {
  //   axios
  //     .get(`${apiURL}/auth/${userId}`)
  //     .then((response) => {
  //       console.log("API key fetched successfully", response);
  //       setApiKey(response.data.apiKey);
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching API key", error);
  //       setApiKey("");
  //     });
  // };

  const getAnimations = () => {
    axios
      .get(`${apiURL}/animations?apiKey=${apiKey}&date=${date}`)
      .then((response) => {
        console.log("Animations fetched successfully", response);
        const filteredAnimations = response.data.animations.filter(
          (animation) => animation.segment === Number(segment)
        );
        const sortedAnimations = filteredAnimations.sort(
          (a, b) => a.index - b.index
        );
        setAnimations(sortedAnimations);
        setAnimationIdx(sortedAnimations[0].index);
      })
      .catch((error) => {
        console.log("Error fetching animations", error);
        setAnimations([]);
      });
  };

  const getVideoData = () => {
    axios
      .get(`${apiURL}/videodata?apiKey=${apiKey}&date=${date}`)
      .then((response) => {
        console.log("Video Data fetched successfully", response);
        const filteredVideoData = response.data.videodata.filter(
          (videodata) => videodata.segment === Number(segment)
        );
        const sortedVideoData = filteredVideoData.sort(
          (a, b) => a.index - b.index
        );
        setVideoData(sortedVideoData);
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log("Error fetching videodata", error);
        setVideoData([]);
      });
  };

  const getColors = () => {
    const updatedColors = animations.reduce(
      (acc, animation) => {
        acc[animation.label] = `#${Math.floor(
          Math.random() * 16777215
        ).toString(16)}`;
        return acc;
      },
      { "": "white", NA: "gray" }
    );
    setColorDict(updatedColors);
  };

  const getLabels = () => {
    const updatedLabels = animations.map((animation) => animation.label);
    setLabels(updatedLabels);
  };

  // useMemo(() => {
  //   if (userId) {
  //     getApiKey();
  //   }
  // }, [userId]);

  useMemo(() => {
    if (date && segment) {
      getVideoData();
      getAnimations();
    }
  }, [userId, date, segment]);

  useMemo(() => {
    getCurrentVideoData();
  }, [videodata, currentPage]);

  useMemo(() => {
    getColors();
    getLabels();
  }, [animations, videodata, segment]);

  const [remaining, setRemaining] = useState(1);
  const countRemaining = () => {
    const url =
      modality === "video" || modality === "audio"
        ? `${apiURL}/modalities?apiKey=${apiKey}&date=${date}`
        : `${apiURL}/annotations?apiKey=${apiKey}&date=${date}`;
    axios
      .get(url)
      .then((response) => {
        const filteredAnnotations = response.data.annotations.filter(
          (annotation) =>
            annotation.segment === Number(segment) &&
            annotation.userId === userId &&
            annotation.label !== ""
        );
        setRemaining(videodata.length - filteredAnnotations.length);
      })
      .catch((error) => {
        console.log("Error fetching annotations", error);
        setRemaining(remaining);
      });
  };
  useEffect(() => {
    countRemaining();
  }, [currentVideoData]);

  const buildAnnotations = async () => {
    let updatedAnnotations = [];

    try {
      await Promise.all(
        currentVideoData.map(async (videodata) => {
          let url =
            modality === "video" || modality === "audio"
              ? `${apiURL}/modalities/?date=${date}&userId=${userId}&index=${videodata.index}&modality=${modality}`
              : `${apiURL}/annotations?date=${date}&userId=${userId}&index=${videodata.index}`;
          try {
            const response = await axios.get(url);
            updatedAnnotations.push(response.data.annotation);
          } catch (error) {
            const annotation = {
              date: videodata.videoDate,
              label: "",
              start: videodata.start,
              end: videodata.end,
              index: Number(videodata.index),
              segment: Number(videodata.segment),
              userId: userId,
              modality: modality,
            };
            try {
              console.log("Creating annotation", annotation);
              let url =
                modality === "video" || modality === "audio"
                  ? `${apiURL}/modalities`
                  : `${apiURL}/annotations`;
              const response = await axios.post(url, annotation);
              console.log("Annotation created successfully", response);
              updatedAnnotations.push(response.data.post._doc);
            } catch (error) {
              console.log("Error creating annotation", error);
            }
          }
        })
      );

      setAnnotations(updatedAnnotations.sort((a, b) => a.index - b.index));
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  const [annotations, setAnnotations] = useState([]);
  const debouncedBuildAnnotations = _.debounce(buildAnnotations, 500);
  useEffect(() => {
    debouncedBuildAnnotations();
    return () => {
      debouncedBuildAnnotations.cancel();
    };
  }, [currentVideoData]);

  return (
    <div className="App">
      <div className="titlecard">
        <img src="spaceman2.png" alt="Spacewalk Logo" width="100"></img>
        <h1>Spacewalk Video Annotation Tool</h1>
      </div>
      <div className="textcard">
        <div className="title">Welcome!</div>
        <div className="content">
          The International Space Station (ISS) is a habitable space station in
          low Earth orbit. Often, astronauts living on the space station will
          conduct “spacewalks” where they go outside the space station.
          Astronauts go on spacewalks for a variety of reasons, including to
          perform science experiments, test equipment, or carry out
          maintenance/repairs. Each spacewalk lasts about 5 hours from when the
          astronauts exit the space station to when they re-enter. For more
          background information on spacewalks and a dictionary of space
          lingo/abbreviations, click{" "}
          <a
            href="https://docs.google.com/document/d/1hzIC58kxhwZQSi_T75ndi34rEfRSYQpAwIHXY5hTPaU/edit?usp=sharing"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </div>
      <div className="textcard">
        <div className="title">Setup</div>
        <div className="content">
          Your task is to label clips from an International Space Station
          spacewalk livestream. First, enter your user ID and select the date
          and segment assigned to you.
        </div>
      </div>
      <SetupForm
        userId={userId}
        segment={segment}
        setUserId={setUserId}
        date={date}
        modality={modality}
        setDate={setDate}
        setSegment={setSegment}
        setParams={setSearchParams}
      />
      <div className="content-container">
        <div className="textcard">
          <div className="title">Animations</div>
          <div className="content">
            Below, you will find a series of animated clips. Each of these clips
            is labeled with a short description. Watch all of these videos to
            get an idea of the tasks the astronauts will be performing during
            this spacewalk.
          </div>
        </div>
        <div className="card-container">
          {animations.map((animation) => (
            <AnimationCard
              key={animation._id}
              animation={animation}
              colorDict={colorDict}
            />
          ))}
        </div>
        <div className="textcard">
          <div className="title">Annotations</div>
          <div className="content">
            Under the "Annotations" tab, you will see a series of short video
            clips. These are clips from the livestream that you are tasked with
            labeling. Below each clip, you will find a selector containing a few
            labels for you to choose from. Your task is to watch each clip and
            select the label that best describes the task being performed. If
            the clip does not contain a task, select "Irrelevant". Remember that
            there will be multiple clips with the same label and the order of
            the clips will roughly correspond to the order of the animations.
            For some tips and FAQs, click{" "}
            <a
              href="https://docs.google.com/document/d/19cKX2u9wLlh2QHbi-fXBVK8Kps6RUtjxcMKvJnugAZs/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            .
          </div>
        </div>
        <div className="card-container">
          {annotations.map((annotation) => (
            <AnnotationCard
              key={annotation._id}
              userId={userId}
              apiURL={apiURL}
              labels={labels}
              colorDict={colorDict}
              annotation={annotation}
              animationIdx={animationIdx}
              modality={modality}
            />
          ))}
        </div>
      </div>
      {/* <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={annotations.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      /> */}

      {currentVideoData.length === 0 ? null : (
        <div className="container">
          <TaskComplete remaining={remaining} segment={segment} />
          <div className="pagination">
            <select
              className="setupform_videoid"
              value={currentPage}
              onChange={(e) => setCurrentPage(e.target.value)}
            >
              {Array.from(
                Array(Math.ceil(videodata.length / PageSize)).keys()
              ).map((page) => (
                <option key={Number(page)} value={Number(page) + 1}>
                  {Number(page) + 1}
                </option>
              ))}
            </select>
            <button
              className="submit_annotations"
              onClick={() => {
                countRemaining();
                setCurrentPage(
                  Math.min(
                    Number(currentPage) + 1,
                    Math.ceil(videodata.length / PageSize)
                  )
                );
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
